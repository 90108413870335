.overlap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.modal {
  position: fixed;
  top: 150px;
  right: 10px;
  left: 411px;
  width: 50%;
  bottom: 10;
  border-radius: 5px;
  justify-content: left;
  background-color: #fff;
}

.modalheadingrow {
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.optionStyles
{
    display: flex;
    
}

.selectText
{
    padding-left: 10px;

}
/* 


// .limit h2{
    //     font-size: 12px !important;
    // }
    // .days select{
    //     width: 100%;
    //     border-radius: 6px;
    //     background-color: #f4f4f4;
    //     height: 34px;
    //     border: none;
    //     font-size: 12px;
    //     font-weight: 500;
    // }
    // .notes{
       
    //     p {
    //         text-align: left;
    //         color: #2f2f2f;
    //         font-size: 10px;
    //         margin-bottom: 4px;
    //     }
    // }
    
    // .btncancalyes{
    //     display: flex;
    //     justify-content: space-between;
    // button {
    //     border-radius: 5px;
    //     background-color: #f4f4f4;
    //     font-size: 12px;
    //     border: none;
    //     margin-top: 16px;
    //     width: 46%;
    //     height: 26px;
    //     font-weight: 500;
    // }
    
    // .yes{
    //     border-radius: 5px;
    //     box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    //     background-color: #bf1736;
    //     color: #fff;
    // }
    // }
    
    // .modal-backdrop {
    //     background-color: green;
    //   }
    
    
    
    //   .tempmodal
    //   {
    //     position: fixed;
    //     top: 85px;
    //     right:52px;
    //     width:22.4%;
    //     padding: 15px;
    //     bottom:10;
    //     border-radius: 5px;
    //     display: flex;
    //     justify-content: center;
    //     box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    //     border: solid 1px #ddd;
    //     background-color: #fff;
    //     .calender {
    //         text-align: center;
    //     }
    //     .user {
    //         text-align: center;
    //     }
    //     h2 {
    //         color: #2f2f2f;
    //         font-size: 10px;
    //         font-weight: bold;
    //         padding: 10px 15px 0;
    //         text-align: center;
    //         margin: 0;
    //     }
    // }
    // .socialIcon
    // {
    //     cursor: pointer;
    // }
    
    
    // .calender {
    //     text-align: center;
    // }
    // .user {
    //     text-align: center;
    // }
    // h2 {
    //     color: #2f2f2f;
    //     font-size: 10px;
    //     font-weight: bold;
    //     padding: 10px 15px 0;
    //     text-align: center;
    //     margin: 0;
    // }
    // p
    // {
    //     text-align: initial;
    //     font-size: 10px;
    //     padding-left: 5px;
    // } */
