.field-error{
    color: red;
    font-size: 10px;
}

.optionStyles
{
    display: flex;
    
}
.selectText
{
    padding-left: 10px;

}
.errorText
{
    margin: 0;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    margin-bottom: 0.35em;
    margin-bottom: 8px;
    display: block;
    color: red;
    padding-top: 8px;
    padding-left: 16px;
}
.trasparentText
{
    color:transparent;
    background-color: transparent;
    font-size: 10px; 
    margin: 0;
    line-height: 1.5;
    font-size: 0.75rem;
    font-family: Public Sans,sans-serif;
    font-weight: 400;
    margin-bottom: 0.35em;
    margin-bottom: 8px;
    display: block;
    padding-top: 8px;
    padding-left: 16px;

}

.flexTableColumn
{
    display: flex;
    align-items: center;
}
.contestText
{
    padding-left: 5px;
}
.filterInput
{
    padding-top: 20px;
    
}

