.overlap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .modal {
    position: fixed;
    top: 150px;
    right: 10px;
    left: 411px;
    width: 50%;
    bottom: 10;
    border-radius: 5px;
    justify-content: left;
    background-color: #fff;
  }
  
  .modalheadingrow {
    padding-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
  }
  .optionStyles
  {
      display: flex;
      
  }
  .selectText
  {
      padding-left: 10px;
  }

  .modalFooterContainer
  {
    text-align: right;
    margin-top: 5px;
    padding-right: 30px;
    padding-bottom: 5px;
    padding-top: 60px;
  }
  .modalContainer
  {
    min-height: 300px!important;
  }
  .trasparentText
  {
    color: transparent;
    background-color: transparent;
  }