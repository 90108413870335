.modalWinnerContainer {
  min-height: 0px !important;
  max-height: 680px !important;
  position: fixed;
  top: 50px !important;
  right: 50px !important;
  left: 57px !important;
  width: 92% !important;
  /* bottom: 10% !important; */
  border-radius: 5px !important;
  justify-content: left !important;
  background-color: #fff !important;
  z-index: 10;
}
.modalheadingWinnerrow {
  padding-top: 10px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px !important;
}

.modalWinnnerFooterContainer {
  text-align: right;
 
  padding-right: 0px !important;
  padding-bottom: 5px;
 
}
.modalInnerContainer {
  margin-left: 0 !important ;
  margin-right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}
.discriptionStyle {
  margin-left: 57px !important;
  width: 261px !important;
}
.dateColumn {
  width: 200px !important;
}
.actiondateColumn {
  width: 200px !important;
}

.overlayCustomClassName {
  z-index: 1301;
}
